import http from "./http";
export default (userId) => ({
  async index(filters) {
    return http
      .index("users", filters)
      .then((json) => ({ users: json }))
      .catch(async (e) => ({ users: null, error: await e }));
  },
  async get() {
    return http
      .get("users", userId)
      .then((json) => ({ user: json }))
      .catch(async (e) => ({ user: null, error: await e }));
  },
  async create(data) {
    return http
      .post("users", data)
      .then((json) => ({ user: json }))
      .catch(async (e) => ({ user: null, error: await e }));
  },
  async update(data) {
    return http
      .put("users", userId, data)
      .then((json) => ({ user: json }))
      .catch(async (e) => ({ user: null, error: await e }));
  },
  async delete() {
    return http
      .delete("users", userId)
      .then((json) => ({ user: json }))
      .catch(async (e) => ({ user: null, error: await e }));
  },
  Control: (controlId) => ({
    async index(filters) {
      return http
        .index(`users/${userId}/controls`, filters)
        .then((json) => ({ controls: json }))
        .catch(async (e) => ({ controls: null, error: await e }));
    },
    async get() {
      return http
        .get(`users/${userId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`users/${userId}/controls`, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`users/${userId}/controls`, controlId, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`users/${userId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
  }),
  Log: (logId) => ({
    async index(filters) {
      return http
        .index(`users/${userId}/logs`, filters)
        .then((json) => ({ logs: json }))
        .catch(async (e) => ({ logs: null, error: await e }));
    },
    async get() {
      return http
        .get(`users/${userId}/logs`, logId)
        .then((json) => ({ log: json }))
        .catch(async (e) => ({ log: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`users/${userId}/logs`, data)
        .then((json) => ({ log: json }))
        .catch(async (e) => ({ log: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`users/${userId}/logs`, logId, data)
        .then((json) => ({ log: json }))
        .catch(async (e) => ({ log: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`users/${userId}/logs`, logId)
        .then((json) => ({ log: json }))
        .catch(async (e) => ({ log: null, error: await e }));
    },
  }),
});

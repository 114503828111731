<template>
  <el-dialog title="Filtrar pedidos" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Período de cadastro:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de conferência:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.checked_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.checked_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de pagamento:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de entrega:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.deliver_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.deliver_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de entrega realizada:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.delivered_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.delivered_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Cliente:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        v-model="filterOptions.client_id"
        size="medium"
        value-key="uid"
        :remote-method="fetchPartners"
        :loading="!partners"
      >
        <el-option
          v-for="item in Partners"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Usuário que cadastrou:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        v-model="filterOptions.created_by"
        size="medium"
        value-key="uid"
        :remote-method="fetchUsers"
        :loading="!users"
      >
        <el-option
          v-for="item in Users"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Vendedor:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        v-model="filterOptions.seller_id"
        size="medium"
        value-key="uid"
        :remote-method="fetchSellers"
        :loading="!sellers"
      >
        <el-option
          v-for="item in Sellers"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Faturamento:</h4>
      </el-row>
      <el-select
        clearable
        multiple
        v-model="filterOptions.payment_methods"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="(item, id) in PaymentMethods"
          :key="id"
          :label="item"
          :value="id"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Possui débitos?</h4>
      </el-row>
      <el-select
        clearable
        v-model="filterOptions.has_debts"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="item in DebtsOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Possui débitos vencidos?</h4>
      </el-row>
      <el-select
        clearable
        v-model="filterOptions.has_expired_debts"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="item in DebtsOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Está na cobrança?</h4>
      </el-row>
      <el-select
        clearable
        v-model="filterOptions.should_charge"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="item in DebtsOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Licitação ou praça?</h4>
      </el-row>
      <el-select
        clearable
        v-model="filterOptions.isPublicSell"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="item in OrderTypes"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Estado do pedido</h4>
      </el-row>
      <el-select
        clearable
        multiple
        v-model="filterOptions.status"
        size="medium"
      >
        <el-option
          v-for="(item, id) in OrderStatus"
          :key="id"
          :label="item"
          :value="id"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Nota Fiscal:</h4>
      </el-row>
      <base-input
        placeholder="Para buscar várias, utilize vírgulas"
        v-model="filterOptions.nfe_number"
      ></base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="clearFilter"
          >Limpar filtros</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateFilters"
          >Filtrar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import FirmService from "../../services/firms";
import PartnerService from "../../services/partners";
import UserService from "../../services/users";
import SellerService from "../../services/sellers";
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: ["showModal", "filters"],
  emits: ["close-modal", "update-filters"],
  data() {
    return {
      filterOptions: this.filters,
      isLoadingSave: false,
      firms: null,
      costs: null,
      users: null,
      sellers: null,
      partners: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  components: {
    BaseInput,
  },
  mounted() {
    this.fetchFirms();
    this.fetchPartners();
    this.fetchUsers();
    this.fetchSellers();
  },
  watch: {
    filters(v) {
      if (v) {
        this.filterOptions = v;
      }
    },
  },
  computed: {
    OrderTypes(){
      return [
        {
          value: true,
          label: 'licitação'
        },
        {
          value: false,
          label: 'praça'
        }
        
      ]
    },
    OrderStatus() {
      return {
        created: "criado",
        canceled: "estornado",
        analisys: "em análise",
        accepted: "aceito",
        paid: "pago",
        production: "em produção",
        ready: "conferência",
        delivery: "em entrega",
        delivered: "entregue",
        completed: "aguardando pagamento",
        finalized: "finalizado",
      };
    },
    DebtsOptions() {
      return [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ];
    },
    PaymentMethods() {
      return this.$store.state.constants.payment_methods;
    },
    Partners() {
      return this.partners || [];
    },
    Sellers() {
      return this.sellers || [];
    },
    Costs() {
      return this.costs || [];
    },
    Users() {
      return this.users || [];
    },
    Firms() {
      return this.firms || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async fetchSellers(v) {
      if (v) {
        const { sellers } = await SellerService().index({
          searchName: v === true ? "" : v,
        });

        if (sellers) this.sellers = sellers;
      }
    },
    async fetchFirms(v) {
      if (v) {
        const { firms } = await FirmService().index({
          name: v === true ? "" : v,
        });

        if (firms) this.firms = firms;
      }
    },
    async fetchPartners(v) {
      if (v) {
        const { partners } = await PartnerService().index({
          is_client: true,
          searchName: v === true ? "" : v,
        });

        if (partners) this.partners = partners;
      }
    },
    async fetchUsers(v) {
      if (v) {
        const { users } = await UserService().index({
          searchName: v === true ? "" : v,
        });

        if (users) this.users = users;
      }
    },
    clearNullableFilters() {
      return Object.entries(this.filterOptions).reduce((t, [key, value]) => {
        if (typeof value !== "undefined")
          if (Array.isArray(value)) {
            if (value.length > 0) t[key] = value;
          } else if (value !== null) t[key] = value;
        return t;
      }, {});
    },
    updateFilters() {
      this.$emit("update-filters", this.clearNullableFilters());
      this.shouldShow = false;
    },
    clearFilter() {
      this.filterOptions = {};
      this.updateFilters();
    },
  },
  name: "FilterOrdersModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
